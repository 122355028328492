import React, { useEffect, useState } from 'react';
import { Container, Button, Dropdown, Form } from 'react-bootstrap';
import LeftNavbar from '../components/LeftNavbar';
import TopHeader from '../components/TopHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate, Link } from 'react-router-dom';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import axios from 'axios';
import { BASE_URL } from '../components/App';
import { useRef } from 'react';
import { FullLoader } from '../components/Loader';
import { CSVDownload, CSVLink } from 'react-csv';

import { useParams } from 'react-router-dom';
import moment from 'moment';
// const { ExportCSVButton } = CSVExport;

const uploadImg = '../img/upload.jpg';

const ChildrenEnrol = () => {
  const Key = useParams();
  const navigate = useNavigate();

  const [csvDownloadFlag, setCsvDownloadFlag] = useState(false);

  const [selectedFranchisee, setSelectedFranchisee] = useState(null);
  const [topSuccessMessage, setTopSuccessMessage] = useState();
  const [fullLoaderStatus, setfullLoaderStatus] = useState(true);
  const [chidlEnroll, setChildEnroll] = useState([]);

  const [Filters, setFilters] = useState(null);
  const [AppyFilter, setApplyFilte] = useState();
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState(' ');
  const [openFilter, setOpenFilter] = useState(false);

  const { SearchBar } = Search;

  const handelApply = () => {
    setApplyFilte(Filters);
    if (openFilter === true) {
      setOpenFilter(false);
    }
  };
  const ResteFilter = () => {
    setApplyFilte('');
    setFilters();
    if (openFilter === true) {
      setOpenFilter(false);
    }
  };

  const ChildernEnrolled = async () => {
    if (selectedFranchisee && selectedFranchisee != "undefined") {
      try {
        let token = localStorage.getItem('token');
        let USER_ROLE = localStorage.getItem('user_role');
        let URL =
          USER_ROLE === 'franchisor_admin'
            ? `${BASE_URL}/children-listing/all-childrens-enrolled/franchisee=${selectedFranchisee}/search=${search}`
            : `${BASE_URL}/children-listing/all-childrens-enrolled/search=${search}`;
        let FilterUrl =
          AppyFilter === '0' || AppyFilter === '1'
            ? `${BASE_URL}/children-listing/all-childrens-enrolled/franchisee=${selectedFranchisee}/special-needs=${AppyFilter}/search=${search}`
            : URL;

        if (URL && FilterUrl) {
          const response = await axios.get(FilterUrl, { headers: { Authorization: 'Bearer ' + token, }, });
          if (response) {
            setfullLoaderStatus(false);
          }

          if (response.status === 200 && response.data.status === 'success') {
            if (response.data.childrenEnrolled) {
              let data = response.data.childrenEnrolled;

              let tempData = data.map((dt, index) => ({
                name: `${dt.fullname} ${dt.family_name} ,${dt.dob}`,
                dob: `${moment(dt.dob).format('DD/MM/YYYY')}`,
                //   franchise: `${dt.user.profile_photo},${dt.user.fullname},${dt.user.franchisee.franchisee_name} `,
                parentName: `${dt?.parents[0]?.user?.fullname} , ${dt?.parents[1]?.user?.fullname},${dt?.parents[2]?.user?.fullname},${dt?.parents[3]?.user?.fullname},${dt?.parents[4]?.user?.fullname},${dt?.parents[5]?.user?.fullname},${dt?.parents[6]?.user?.fullname},${dt?.parents[7]?.user?.fullname},${dt?.parents[8]?.user?.fullname},${dt?.parents[9]?.user?.fullname},${dt?.parents[0]?.user?.parent_profile_photo},${dt?.parents[1]?.user?.parent_profile_photo},${dt?.parents[2]?.user?.parent_profile_photo},${dt?.parents[3]?.user?.parent_profile_photo},${dt?.parents[4]?.user?.parent_profile_photo},${dt?.parents[5]?.user?.parent_profile_photo},${dt?.parents[6]?.user?.parent_profile_photo},${dt?.parents[7]?.user?.parent_profile_photo},${dt?.parents[8]?.user?.parent_profile_photo},${dt?.parents[9]?.user?.parent_profile_photo}`,
                educatorassisgned: `${dt?.users[0]?.fullname}, ${dt?.users[1]?.fullname},${dt?.users[2]?.fullname},${dt?.users[3]?.fullname},${dt?.users[4]?.fullname},${dt?.users[5]?.fullname},${dt?.users[6]?.fullname},${dt?.users[7]?.fullname},${dt?.users[8]?.fullname},${dt?.users[9]?.fullname},${dt?.users[0]?.educator_profile_photo}, ${dt?.users[1]?.educator_profile_photo},${dt?.users[2]?.educator_profile_photo},${dt?.users[3]?.educator_profile_photo},${dt?.users[4]?.educator_profile_photo},${dt?.users[5]?.educator_profile_photo},${dt?.users[6]?.educator_profile_photo},${dt?.users[7]?.educator_profile_photo},${dt?.users[8]?.educator_profile_photo},${dt?.users[9]?.educator_profile_photo}`,
                specailneed: `${dt?.has_special_needs}`,
                franchise: `${dt?.franchisee_id}`,
                enrolldate: `${dt?.start_date}`,
                franchise: `${dt?.franchisee?.franchisee_name}`,
                ids: `${dt?.parents?.[0]?.user_parent_id}-${dt?.id}`,
              }));
              setChildEnroll(tempData);

              let temp = tempData;
              let csv_data = [];
              temp.map((item, index) => {
                delete item.is_deleted;

                // delete item.user_id;

                csv_data.push(item);
                let data = { ...csv_data[index] };

                let d = data.parentName.split(',');
                let educator = data.educatorassisgned.split(',');

                let educatorArray = [];

                let parent = [];
                d.map((item, index) => {
                  if (
                    item.trim() != 'undefined' &&
                    item.trim() != 'null' &&
                    item.trim().split('.').pop() != 'blob'
                  ) {
                    parent[index] = ' ' + item.trim();
                  }
                });
                educator.map((item, index) => {
                  if (
                    item.trim() !== 'undefined' &&
                    item.trim() !== 'null' &&
                    item.trim().split('.').pop() !== 'blob'
                  ) {
                    educatorArray[index] = ' ' + item.trim();
                  }
                });

                data['specailneed'] = data.specailneed == 0 ? 'No' : 'Yes';
                data['enrolldate'] = moment(data.enrolldate).format('DD/MM/YYYY');
                data['parentName'] = parent;
                data['educatorassisgned'] = educatorArray;
                data['name'] = data.name.split(',')[0];

                csv_data[index] = data;
              });
              setCsvData(csv_data);
            }
            else {
              setChildEnroll([]);
            }
          }
        }
      } catch (error) {
        setfullLoaderStatus(false);
        setChildEnroll([]);
      }
    }
  };

  useEffect(() => {
    if (selectedFranchisee) {
      ChildernEnrolled();
    }
  }, [selectedFranchisee, AppyFilter, Filters, search]);
  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell) => {
        cell = cell.split(',');

        return (
          <>
            <div className="user-list">
              <span className="user-name">
                {/* {cell[0]}  */}
                {cell[0][0].toUpperCase() + cell[0].slice(1)}

                <small>
                  {/* EnrolmentInitiated<br /> */}
                  DOB: {moment(cell[1]).format('DD/MM/YYYY')}
                </small>
              </span>
            </div>
          </>
        );
      },
    },

    {
      dataField: 'parentName',
      text: 'Parent Name',
      formatter: (cell) => {
        cell = cell.split(',');
        // const cols = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        return (
          <div className="parentName" style={{ maxHeight: '100px', overflowY: 'scroll' }}>
            {/* {cols.map((x, i) => */}
            <GetUserProfileImage cell={cell[0]} cell2={cell[(0 + 10)].trim()} />
            <GetUserProfileImage cell={cell[1]} cell2={cell[(1 + 10)].trim()} />
            <GetUserProfileImage cell={cell[2]} cell2={cell[(2 + 10)].trim()} />
            <GetUserProfileImage cell={cell[3]} cell2={cell[(3 + 10)].trim()} />
            <GetUserProfileImage cell={cell[4]} cell2={cell[(4 + 10)].trim()} />
            <GetUserProfileImage cell={cell[5]} cell2={cell[(5 + 10)].trim()} />
            <GetUserProfileImage cell={cell[6]} cell2={cell[(6 + 10)].trim()} />
            <GetUserProfileImage cell={cell[7]} cell2={cell[(7 + 10)].trim()} />
            <GetUserProfileImage cell={cell[8]} cell2={cell[(8 + 10)].trim()} />
            <GetUserProfileImage cell={cell[8]} cell2={cell[(8 + 10)].trim()} />
            <GetUserProfileImage cell={cell[9]} cell2={cell[(9 + 10)].trim()} />
            {/* )} */}
          </div>
        );
      },
    },
    {
      dataField: 'educatorassisgned',
      text: 'Educator Assigned',

      formatter: (cell) => {
        cell = cell.split(',');
        return (
          <>
            <div
              className="parentName"
              style={{ maxHeight: '100px', overflowY: 'scroll' }}
            >
              {cell[0].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    {/* <img src={ cell[1] === "null"  ? "../img/upload.jpg" : cell[1]} /> */}
                    <img src={cell[10] === 'undefined' || cell[10].trim() === 'null' ? uploadImg : cell[10]} />
                  </span>
                  <span className="user-name">
                    {cell[0][0].toUpperCase() + cell[0].slice(1)}
                    {/* <span>{cell[1]}</span> */}
                  </span>
                </div>
              )}
              {cell[1].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[11] === 'undefined' || cell[11].trim() === 'null' ? uploadImg : cell[11]} />
                  </span>
                  <span className="user-name">
                    {cell[1][0].toUpperCase() + cell[1].slice(1)}
                  </span>
                </div>
              )}
              {cell[2].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[12].trim() === 'undefined' || cell[12].trim() === 'null' ? uploadImg : cell[12]} />
                  </span>
                  <span className="user-name">
                    {cell[2][0].toUpperCase() + cell[2].slice(1)}
                  </span>
                </div>
              )}
              {cell[3].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[13].trim() === 'undefined' || cell[13].trim() === 'null' ? uploadImg : cell[13]} />
                  </span>
                  <span className="user-name">
                    {cell[3][0].toUpperCase() + cell[3].slice(1)}
                  </span>
                </div>
              )}

              {cell[4].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[14].trim() === 'undefined' || cell[14].trim() === 'null' ? uploadImg : cell[14]} />
                  </span>
                  <span className="user-name">
                    {cell[4][0].toUpperCase() + cell[4].slice(1)}
                  </span>
                </div>
              )}
              {cell[5].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[15].trim() === 'undefined' || cell[15].trim() === 'null' ? uploadImg : cell[15]} />
                  </span>
                  <span className="user-name">
                    {cell[5][0].toUpperCase() + cell[5].slice(1)}
                  </span>
                </div>
              )}

              {cell[6].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[16].trim() === 'undefined' || cell[16].trim() === 'null' ? uploadImg : cell[16]} />
                  </span>
                  <span className="user-name">
                    {cell[6][0].toUpperCase() + cell[6].slice(1)}
                  </span>
                </div>
              )}

              {cell[7].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[17].trim() === 'undefined' || cell[17].trim() === 'null' ? uploadImg : cell[17]} />
                  </span>
                  <span className="user-name">
                    {cell[7][0].toUpperCase() + cell[7].slice(1)}
                  </span>
                </div>
              )}

              {cell[8].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[18].trim() === 'undefined' || cell[18].trim() === 'null' ? uploadImg : cell[18]} />
                  </span>
                  <span className="user-name"> {cell[8][0].toUpperCase() + cell[8].slice(1)} </span>
                </div>
              )}
              {cell[9].trim() != 'undefined' && (
                <div className="user-list">
                  <span className="user-pic">
                    <img src={cell[19].trim() === 'undefined' || cell[19].trim() === 'null' ? uploadImg : cell[19]} />
                  </span>
                  <span className="user-name"> {cell[9][0].toUpperCase() + cell[9].slice(1)} </span>
                </div>
              )}
              {/* {
              cell[18].trim() != "undefined" &&
              <div className="user-list">
                <span className="user-pic">
                  <img src={cell[19].trim() === "undefined" || cell[19].trim() === "null" ? "../img/upload.jpg" : cell[19]} />
                </span><span className="user-name">{

                  cell[18][0].toUpperCase() + cell[18].slice(1)
                }
                </span>
              </div>
            } */}
            </div>
          </>
        );
      },
    },
    {
      dataField: 'specailneed',
      text: 'Special Needs',
      formatter: (cell) => {
        return (
          <>
            <div className="user-list">
              <span className="user-name"> {cell === '1' ? 'Yes' : <>{cell === '0' ? ' No' : ' '}</>} </span>
            </div>
          </>
        );
      },
    },
    // {
    //   dataField: 'DBO',
    //   text: 'Date Of Birth',
    // },
    {
      dataField: 'enrolldate',
      text: 'Enrolment Initiated ',
      formatter: (cell) => {
        return (
          <>
            <div className="user-list"> <span className="user-name"> {moment(cell).format('DD/MM/YYYY')}{' '} </span> </div>
          </>
        );
      },
    },
    {
      dataField: 'franchise',
      text: 'Franchise ',
      formatter: (cell) => {
        // cell = cell.split(",");
        return (
          <>
            <div className="user-list"> <span className="user-name"> {cell === 'undefined' || cell === 'null' ? ' ' : cell}{' '} </span> </div>
          </>
        );
      },
    },
    {
      dataField: 'ids',
      text: 'Edit Child',
      formatter: (cell) => {
        console.log('Cell:::', cell);
        let [parent_id, child_id] = cell?.split('-');
        console.log('Parent id::::', parent_id);
        if (typeof parent_id === 'undefined') return null;
        return (
          <>
            <button className="btn btn-outline-secondary" onClick={() => navigate(`/child-enrollment-init/edit/${child_id}/${parent_id}`, { state: { from: 'child-listing' }, })} > Edit Child </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('success_msg')) {
      setTopSuccessMessage(localStorage.getItem('success_msg'));
      localStorage.removeItem('success_msg');
      setTimeout(() => {
        setTopSuccessMessage(null);
      }, 3000);
    }
  }, []);
  const headers = [
    { label: 'NAME', key: 'name' },
    { label: 'DOB', key: 'dob' },
    { label: 'PARENT NAME', key: 'parentName' },
    { label: 'EDUCATOR ASSIGNED', key: 'educatorassisgned' },
    { label: 'SPECIAL NEEDS', key: 'specailneed' },
    { label: 'FRANCHISE', key: 'franchise' },
    { label: 'ENROLMENT INITIATION DATE', key: 'enrolldate' },
  ];

  console.log('Child Enrol:::', chidlEnroll);
  return (
    <>
      <div id="main">
        <section className="mainsection">
          <Container>
            <div className="admin-wrapper">
              <aside className="app-sidebar"> <LeftNavbar /> </aside>
              <div className="sec-column">
                <TopHeader setSelectedFranchisee={(s) => { setfullLoaderStatus(true); setSelectedFranchisee(s); }} />
                <FullLoader loading={fullLoaderStatus} />
                <ToolkitProvider keyField="name" data={chidlEnroll} columns={columns} search exportCSV={{ fileName: 'Children Enroled.csv', }} >
                  {(props) => (
                    <>
                      <div className="entry-container">
                        <div className="user-management-sec childenrol-table">
                          <>
                            {topSuccessMessage && (
                              <p className="alert alert-success" style={{ position: 'fixed', left: '50%', top: '0%', zIndex: 1000, }} > {topSuccessMessage} </p>
                            )}
                            <header className="title-head">
                              <h1 className="title-lg">Children Enroled</h1>
                              <div className="othpanel">
                                <div className="extra-btn">
                                  <div className="data-search me-3">
                                    <Form.Group className="d-flex" style={{ position: 'relative' }} >
                                      <div className="user-search"> <img src="./img/search-icon-light.svg" alt="" /> </div>
                                      <Form.Control className="searchBox" type="text" placeholder="Search" name="search"
                                        onChange={(e) => {
                                          //   setSearch(e.target.value, () => {
                                          //     onFilter();
                                          //  });
                                          setSearch(e.target.value);
                                          // onFilter(e.target.value);
                                        }}
                                      />
                                    </Form.Group>
                                  </div>

                                  <Dropdown className="filtercol me-3">
                                    <Dropdown.Toggle id="extrabtn" variant="btn-outline" onClickCapture={() => { if (openFilter === false) setOpenFilter(true); }}> <i className="filter-ico"></i> Add Filters </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <header>Filter by:</header>

                                      <div className="custom-radio btn-radio mb-2">
                                        <label>Special Needs:</label>
                                        <Form.Group inline>
                                          <Form.Check inline label="Yes" value="1" name="users" type="radio" id="yes-1" checked={Filters === '1'} onChange={(event) => { setFilters('1'); }} />
                                          <Form.Check inline label="No" value="0" name="users" type="radio" id="No-0" checked={Filters === '0'} onChange={(event) => { setFilters('0'); }} />
                                        </Form.Group>
                                      </div>

                                      <footer>
                                        <Dropdown.Item as="button" className="btn btn-transparent w-auto d-inline-block" type="submit" onClick={ResteFilter} > Reset </Dropdown.Item>
                                        <Dropdown.Item as="button" className="btn btn-primary w-auto d-inline-block" type="submit" onClickCapture={handelApply} > Apply </Dropdown.Item>
                                        {/* <Button variant="transparent" type="submit" onClick={ResteFilter} > Reset </Button>
                                          <Button variant="primary" type="submit" onClick={handelApply} > Apply </Button> */}
                                      </footer>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <Dropdown>
                                    <Dropdown.Toggle id="extrabtn" className="ctaact" > <img src="../img/dot-ico.svg" alt="" /> </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item as="button"> <CSVLink data={csvData} filename={'Children Enroled.csv'} headers={headers} target="_blank" > {'Export CSV'} </CSVLink> </Dropdown.Item>

                                      {/* <Dropdown.Item onClick={() => { onDeleteAll() }}>
Delete All Row
</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  {/* {localStorage.getItem('user_role') === "franchisor_admin" ? ( */}
                                  {/* <Dropdown>
                                    <Dropdown.Toggle
                                      id="extrabtn"
                                      className="ctaact"
                                    >
                                      <img src="../img/dot-ico.svg" alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => {
                                          setCsvDownloadFlag(true);
                                        }}
                                      >
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            as="button"
                                          >
                                            <CSVLink
                                              data={csvData}
                                              filename={"Children Enroled.csv"}
                                              headers={headers}
                                              target="_blank"
                                            >

                                            </CSVLink>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown> */}
                                  {/* ) : (null)} */}
                                </div>
                              </div>
                            </header>
                          </>
                          {!fullLoaderStatus && chidlEnroll && chidlEnroll?.length > 0 && <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />}

                          {!fullLoaderStatus && chidlEnroll?.length == 0 && <div className="text-center mb-5 mt-5"> <strong>No child enrol yet</strong> </div>}
                        </div>
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default ChildrenEnrol;


const GetUserProfileImage = ({ cell, cell2 }) => {

  return (<>
    {cell.trim() != 'undefined' && (
      <div className="user-list">
        <span className="user-pic">
          <img src={cell2 === 'undefined' || cell2 === 'null' ? uploadImg : cell2} />
        </span>
        <span className="user-name">
          {cell === 'undefined' ? null : cell[0].toUpperCase() + cell.slice(1)}
        </span>
      </div>
    )}
  </>)
}
