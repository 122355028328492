import { faEllipsisVertical, faEye, faPen, faPlus, faRemove, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Dropdown, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import LeftNavbar from "../../components/LeftNavbar";
import TopHeader from "../../components/TopHeader";
import { BASE_URL, IGNORE_REMOVE_FORM } from "../../components/App";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FullLoader } from "../../components/Loader";
import { MonaRoles } from "../../constant";
const monaRoles = MonaRoles;
const img1 = "../img/form-user-round.svg";

// function returnResponseCount(data, educatorIDs, form, LSUserId, LSUserRole) {
//   if (LSUserRole === monaRoles.educator) {
//     if (data.length > 0) {
//       let educatorResponseData = [];
//       educatorResponseData = data.map((item) => {
//         let result = item.filter((d) => {
//           if (
//             educatorIDs?.includes(d.behalf_of) ||
//             d.behalf_of === ParsedLSUserId
//           ) { return d; }
//         });
//         return result[0];
//       });

//       educatorResponseData = educatorResponseData.filter((item) => typeof item !== "undefined");
//       return educatorResponseData.length;
//     }
//     return 0;
//   }

//   return data.length === 0 ? 0 : data.length;
// }

const RoleGuardian = monaRoles.guardian;

function ViewFormBuilder(props) {
  const navigate = useNavigate();
  const [viewResponseFlag, setViewResponseFlag] = useState(false);
  const [formData, setFormData] = useState([]);
  const [Index, setIndex] = useState(0);
  const [educatorIDs, setEducatorIDs] = useState(null);
  const [educatorChildren, setEducatorChildren] = useState(null);
  const [innerIndex, setInnerIndex] = useState(0);
  const [MeFormData, setMeFormData] = useState([]);
  const [SelectedFormData, setSelectedFormData] = useState({ form_data: [] });
  const [meformDataStatus, setMeformDataStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherformDataStatus, setOtherformDataStatus] = useState(false);

  const [selectedFranchisee, setSelectedFranchisee] = useState(
    localStorage.getItem("franchisee_id")
  );
  const [fullLoaderStatus, setfullLoaderStatus] = useState(true);
  const [formId, setFormId] = useState(null);
  const [OthersFormData, setOthersFormData] = useState([]);

  const [LSChildId, setLSChildId] = useState(localStorage.getItem("child_id"));
  const [LSUserRole, setLSUserRole] = useState(localStorage.getItem('user_role'));
  const [LSUserId, setLSUserId] = useState(localStorage.getItem('user_id'));
  const [ParsedLSUserId, setParsedLSUserId] = useState(parseInt(localStorage.getItem('user_id')));

  const [key, setKey] = useState([monaRoles.coordinator, monaRoles.educator, monaRoles.guardian].includes(LSUserRole) ? "created-by-others" : "created-by-me");

  const token = localStorage.getItem("token");
  const location = useLocation();
  let no_record = false;
  let form_history_no_record = false;
  let count = 0;

  useEffect(() => {
    getAllForm();
    if (location?.state?.message) {
      toast.success(location?.state?.message);
      navigate("/form", { state: { message: null } });
    }
  }, []);

  const dateCheck = (start_date, start_time, end_date, end_time, form_name) => {
    let todayDate = new Date();
    todayDate = new Date(todayDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
    todayDate = new Date(todayDate);
    if (start_date) {
      let dataAndTime = start_date + " " + start_time;
      let startDate = new Date(dataAndTime);
      startDate = new Date(startDate);
      if (todayDate.getTime() < startDate.getTime()) {
        toast.error("You can't open this form because this form start date is " + moment(start_date).format("DD/MM/YYYY") + " " + start_time + ".");
      } else {
        if (end_date) {
          let dataAndTime = end_date + " " + end_time;
          let endDate = new Date(dataAndTime);
          endDate = new Date(endDate);
          if (todayDate.getTime() > endDate.getTime()) {
            toast.error("Your form was expired on " + moment(end_date).format("DD/MM/YYYY") + " " + end_time + ".");
          } else navigate(`/form/dynamic/${form_name}`);
        } else navigate(`/form/dynamic/${form_name}`);
      }
    } else navigate(`/form/dynamic/${form_name}`);
  };

  const getAllForm = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/form/list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result?.result?.map((item) => {
          if (item.form_name.toLowerCase() === IGNORE_REMOVE_FORM.toLowerCase()) {
            setFormId(item.id);
          }
        });
      })
      .catch((error) => console.log("error", error));
  };

  const deleteForm = (id, formCategory) => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${BASE_URL}/form/${id}?user_id=${LSUserId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success(result?.message);
        getFormData("", localStorage.getItem("franchisee_id"), "update");
      })
      .catch((error) => console.log("error", error));
  };

  const getFormData = (search, franchisee_id, place = "") => {
    if (!loading) {
      //setfullLoaderStatus(true);
      setLoading(true);
      console.log("getFormData called", place);
      var myHeaders = new Headers();
      myHeaders.append("authorization", "Bearer " + token);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };
      LSUserRole === RoleGuardian ? (franchisee_id = "all") : (franchisee_id = franchisee_id);
      fetch(`${BASE_URL}/form?search=${search}&id=${localStorage.getItem("user_id")}&role=${LSUserRole}&franchisee_id=${franchisee_id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setFormData(result?.result);
          let me = [];
          let others = [];
          result?.result.map((item, index) => {
            me.push(item);
            others.push(item);
            me.forms = [];
            others.forms = [];
            item.forms = item?.forms.sort(
              (a, b) => new moment(b.createdAt) - new moment(a.createdAt)
            );
            item?.forms?.map((inner_item, inner_index) => {
              if (inner_item.created_by === ParsedLSUserId) {
                me.forms.push(inner_item);
              } else {
                others.forms.push(inner_item);
              }
            });
            if (me.forms.length === 0) {
              delete me[index];
            }
            if (others.forms.length === 0) {
              delete others[index];
            }
          });
          setMeFormData(me);

          // MUTATING THE OTHER FORM DATA;
          // others = modifySeenCountForEducators(others, educatorIDs);

          setOthersFormData(others);
          if (result) {
            setfullLoaderStatus(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setfullLoaderStatus(false);
          setLoading(false);
        });
    }
    // setfullLoaderStatus(false);
  };

  const seenFormResponse = (data) => {
    let seenData = [];
    data?.map((item) => {
      item?.map((inner_item) => {
        seenData.push({ id: inner_item.id, user_id: LSUserId, });
      });
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(seenData),
      redirect: "follow",
    };
  };

  const fetchChildrenForThisEdcuator = async (educatorId) => {
    let response = await axios.get(`${BASE_URL}/enrollment/get-children-by-educator-id/${educatorId}`, { headers: { Authorization: `Bearer ${token}`, }, });

    if (response.status === 200 && response.data.status === "success") {
      let { children } = response.data.educator;
      let childIds = children.map((child) => child.id);
      let childrenNames = children.map((child) => child.fullname);
      setEducatorChildren(childrenNames);
      setEducatorIDs(childIds);
    }
  };

  const fetchChildrenForGuardian = async () => {
    let response = await axios.get(`${BASE_URL}/enrollment/parent/getChildrenByParentUserId/${LSUserId}`, { headers: { Authorization: `Bearer ${token}`, }, });

    if (response.status === 200 && response.data.status === "success") {
      let { children } = response.data;
      let childIds = children.map((child) => child.id);
      let childrenNames = children.map((child) => child.fullname);

      setEducatorChildren(childrenNames);
      setEducatorIDs(childIds);

      console.log(educatorChildren);
      console.log(childIds);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("form_error")) {
      toast.error(localStorage.getItem("form_error"));
      localStorage.removeItem("form_error");
    }
  }, []);

  useEffect(() => {
    console.log("**Role**")
    console.log(LSUserRole);
    if (LSUserRole === monaRoles.educator) {
      let userId = LSUserId;
      fetchChildrenForThisEdcuator(userId);
    }

    if (LSUserRole === monaRoles.parent || LSUserRole === monaRoles.guardian) {  //changes Bug 35 [17-Oct-2024]
      fetchChildrenForGuardian();
    }
  }, []);


  const getResponseTow = (id, inner_index, index) => {
    setfullLoaderStatus(true);
    var myHeaders = new Headers();
    myHeaders.append('authorization', 'Bearer ' + token);
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    const Url = `${BASE_URL}/form/responsedata?search=&form_id=${id}&user_id=${LSUserId}&user_role=${LSUserRole}`
    fetch(Url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSelectedFormData({ form_data: result?.result || [] });
        // setResponseData(result?.result);
        // setFormData(result?.form);
        if (result) {
          setfullLoaderStatus(false);
          setViewResponseFlag(true);
          setInnerIndex(inner_index); setIndex(index);
        }
      })
      .catch((error) => console.log('error', error));
  };


  MeFormData && console.log("ME FORM DATA>>>>>>>>>>", MeFormData);
  OthersFormData && console.log("OTHER FORM DATA>>>", OthersFormData);
  // educatorIDs && console.log('EDUCATOR ID>>>>>>>>>>', educatorIDs);


  const getCategoryImage = (category) => {
    return category === "Parent Forms" ? "../img/survey_icon.png" : category === "Talent Management" ? "../img/blue_survey.png" : category === "Child Care Forms" ? "../img/green_survey.png" : category === "Business Operations" ? "../img/dark_green_survey.png" : category === "Customer Service" ? "../img/gray_survey.png" : category === "Governance & Compliance" ? "../img/pink_survey.png" : category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png"
  }

  const OpenViewResponse = (url, inner_item) => {
    const end_date = inner_item.end_date;
    if (end_date) {
      let todayDate = new Date();
      todayDate = new Date(todayDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
      todayDate = new Date(todayDate);
      let dataAndTime = end_date + " " + inner_item.end_time;
      let endDate = new Date(dataAndTime);
      endDate = new Date(endDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
      endDate = new Date(endDate);
      if (todayDate.getTime() > endDate.getTime())
        toast.error("Your form was expired on " + moment(end_date).format("DD/MM/YYYY") + ".");
      else
        navigate(url);
    } else
      navigate(url);
  }

  const method1 = (formData, index, inner_index, inner_item) => {
    if ((formData[index]?.forms[inner_index]?.form_permissions[0]?.signatories_role || [])?.includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole)) {
      navigate(`/form/response/${inner_item.id}`, { state: { signature_access: true, }, });
    } else {
      navigate(`/form/response/${inner_item.id}`, { state: { signature_access: false, }, });
    }
  }

  const getUserImgCount = (inner_item, inner_index, index) => {
    return (
      <div className="user-img" onClick={() => { getResponseTow(inner_item.id, inner_index, index); }} >
        <img src={img1} />
        {inner_item?.seen_count > 0 && (
          <span > {inner_item?.seen_count} </span>
        )}
      </div>
    )
  }

  const getResponseCount = (inner_item, inner_index, index) => {
    return (<div className="content-title-section">
      <h6>{inner_item.form_name}</h6>
      <h4> Created on:{" "} {moment(inner_item.createdAt).utcOffset("+11:00").format("DD/MM/YYYY")} </h4>
      {/* {inner_item.form_data?.length && inner_item.form_data?.length > 0 ? ( */}
      {inner_item.total && inner_item.total > 0 ? (
        // <h4 onClick={() => { getResponseTow(inner_item.id); setViewResponseFlag(true); setInnerIndex(inner_index); setIndex(index); }} >
        <h4 onClick={() => { getResponseTow(inner_item.id, inner_index, index); }} >
          Total Responses :{" "}
          {inner_item?.total}
          {/* {inner_item.form_data?.length} */}
          {inner_item?.seen_count > 0 && (<> {" "} | <b> {" "} New : {inner_item?.seen_count} </b> </>
          )}
        </h4>
      ) : ("")}
    </div>)
  }

  const bindDropDown = (inner_item, item, addLocalS) => {
    return (
      <Dropdown> <Dropdown.Toggle id="dropdown-basic1">
        <FontAwesomeIcon icon={faEllipsisVertical} /> </Dropdown.Toggle> <Dropdown.Menu>
          <Dropdown.Item onClick={() => { navigate("/form/add", { state: { id: inner_item.id, update: true, }, }); }} >
            <FontAwesomeIcon icon={faPen} />{" "} Edit </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            if (addLocalS) { localStorage.setItem("formStatus", false); }
            navigate(`/form/preview/${inner_item.form_name}`, { state: { id: inner_item.id, form_name: inner_item.form_name, }, });
          }} >
            <FontAwesomeIcon icon={faEye} />{" "} Preview </Dropdown.Item>
          {inner_item.id !== formId && (<Dropdown.Item onClick={() => { if (window.confirm("Are you sure you want to delete the form?")) { deleteForm(inner_item.id, item.category); } }} >
            <FontAwesomeIcon icon={faRemove} />{" "} Remove </Dropdown.Item>)} </Dropdown.Menu> </Dropdown>
    )
  }

  return (
    <>
      <div id="main">
        <section className="mainsection">
          <Container>
            <div className="admin-wrapper">
              <aside className="app-sidebar"> <LeftNavbar /> </aside>
              <div className="sec-column">
                <TopHeader selectedFranchisee={selectedFranchisee}
                  setSelectedFranchisee={(id) => {
                    id = LSUserRole === RoleGuardian ? localStorage.setItem("child_id", id) : id;
                    if (count === 0) {
                      getFormData("", id, "setSelectedFranchisee");
                      count++;
                    }
                    setSelectedFranchisee(id);
                    localStorage.setItem("f_id", id);
                  }}
                />
                <FullLoader loading={fullLoaderStatus} />

                <div className="forms-header-section">
                  <div className="forms-managment-section">
                    <div className="forms-managment-left"> <h6>Forms Management</h6> </div>
                    <div className="forms-managment-right">
                      <div className="forms-search">
                        <Form.Group>
                          <div className="forms-icon"> <img src="../img/search-icon-light.svg" alt="" /> </div>
                          <Form.Control type="text" placeholder="Search..." name="search" onChange={(e) => { getFormData(e.target.value, localStorage.getItem("franchisee_id"), "Forms Management"); }} />
                        </Form.Group>
                      </div>
                      {(LSUserRole === MonaRoles.franchiseeAdmin || LSUserRole === MonaRoles.franchisorAdmin) && (
                        <div className="forms-create">
                          <Button variant="primary" onClick={() => { navigate("/form/add"); }} > <FontAwesomeIcon icon={faPlus} /> Create New Form </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tab-section">
                    <Tabs defaultActiveKey={location?.state?.form_template ? "form-templates" : "forms-to-complete"} id="uncontrolled-tab-example" className="mb-3" >

                      <Tab eventKey="forms-to-complete" title="Forms to complete">
                        <div className="forms-content-section">
                          {formData?.map((item, index) => {
                            return (
                              ((item.category === "Talent Management" && LSUserRole !== RoleGuardian) || LSUserRole !== RoleGuardian || (item.category !== "Talent Management" && LSUserRole === RoleGuardian)) && (
                                <>
                                  <Row key={index + "1"}>
                                    {(item["title_flag"] = false)}
                                    {item?.forms?.map((inner_item, inner_index) => {
                                      return inner_item?.end_date &&
                                        (((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.fill_access_users) || []).includes("parent") &&
                                          ((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions != "undefined" && inner_item?.form_permissions[0]?.target_user) || []).includes("parent")
                                          ? !(inner_item?.form_filled_user || []).includes(LSUserRole === RoleGuardian ? LSUserId : LSUserId)
                                          : !(inner_item?.form_filled_user || []).includes(LSUserRole === RoleGuardian ? LSChildId : LSUserId)) &&
                                        (((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.fill_access_users) || []).includes(
                                          LSUserRole === RoleGuardian ? "parent" : LSUserRole) ||
                                          ((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.fill_access_users) || []).includes(
                                            LSUserRole === RoleGuardian ? LSChildId || LSUserId : LSUserId)) ? (
                                        <>
                                          {item.title_flag === false && (
                                            <>
                                              {(item["title_flag"] = true)}
                                              {(no_record = true)}
                                              <Col lg={12}> <h2 className="page_title">{item.category}</h2> </Col>
                                            </>
                                          )}

                                          <Col lg={4} key={inner_index + "2"} onClick={() => { dateCheck(inner_item.start_date, inner_item.start_time, inner_item.end_date, inner_item.end_time, inner_item.form_name); }} >
                                            <div className="forms-content create-other">
                                              <div className="content-icon-section">
                                                <img src={getCategoryImage(item.category)} />
                                                {/* <img src={ item.category === "Parent Forms" ? "../img/survey_icon.png" : item.category === "Talent Management" ? "../img/blue_survey.png" : item.category === "Child Care Forms" ? "../img/green_survey.png" : item.category === "Business Operations" ? "../img/dark_green_survey.png" : item.category === "Customer Service" ? "../img/gray_survey.png" : item.category === "Governance & Compliance" ? "../img/pink_survey.png" : item.category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png" } /> */}
                                              </div>
                                              <div className="content-title-section">
                                                <h6>{inner_item.form_name}</h6>
                                                <h4 className="due_date"> Due on:{" "} {moment(inner_item.end_date).format("DD/MM/YYYY")} </h4>
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      ) : null;
                                    })}
                                  </Row>
                                  <Row>
                                    {item?.forms?.map((inner_item, inner_index) => {
                                      let formPermissions = inner_item?.form_permissions || [];
                                      let fieldPermission = [];
                                      inner_item?.form_fields?.map((fields) => {
                                        fields?.form_field_permissions[0] !== undefined &&
                                          fieldPermission.push(fields?.form_field_permissions[0].fill_access_users);
                                      });
                                      let array1 = [];
                                      if (fieldPermission.length > 0) {
                                        fieldPermission.map((permission) => {
                                          if (permission !== undefined && permission !== "" && permission !== null) { for (let name of permission) { array1.push(name); } }
                                        });

                                        fieldPermission = [];
                                        fieldPermission = array1;
                                      }
                                      return inner_item?.end_date === null &&
                                        (((typeof formPermissions !== "undefined" && formPermissions[0]?.fill_access_users) || [])?.includes("parent") &&
                                          ((typeof formPermissions !== "undefined" && formPermissions[0]?.target_user) || []
                                          )?.includes("parent")
                                          ? !(inner_item?.form_filled_user || [])?.includes(LSUserRole === RoleGuardian ? LSUserId : LSUserId)
                                          : !(inner_item?.form_filled_user || [])?.includes(LSUserRole === RoleGuardian ? LSChildId : LSUserId)) &&
                                        (((typeof fieldPermission !== "undefined" && fieldPermission) || [])?.includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole) ||
                                          ((typeof formPermissions !== "undefined" && formPermissions[0]?.fill_access_users) || []
                                          )?.includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole) || ((typeof formPermissions !== "undefined" && formPermissions[0]?.fill_access_users) || [])?.includes(LSUserRole === RoleGuardian ? LSChildId : LSUserId)) ? (
                                        <>
                                          {item.title_flag === false && (
                                            <>
                                              {(item["title_flag"] = true)}
                                              {(no_record = true)}
                                              <Col lg={12} key={inner_index + "3"}> <h2 className="page_title">{item.category}</h2> </Col>
                                            </>
                                          )}
                                          <Col lg={4}
                                          // onClick={() => {
                                          //   dateCheck(
                                          //     inner_item.start_date,
                                          //     inner_item.start_time,
                                          //     inner_item.end_date,
                                          //     inner_item.end_time,
                                          //     inner_item.form_name
                                          //   );
                                          // }}
                                          >
                                            <div className="forms-content create-other">
                                              <div className="content-icon-section" onClick={() => { navigate(`/form/dynamic/${inner_item.form_name}`); }}>
                                                <img src={getCategoryImage(item.category)} />
                                                {/* <img src={ item.category === "Parent Forms" ? "../img/survey_icon.png" : item.category === "Talent Management" ? "../img/blue_survey.png" : item.category === "Child Care Forms" ? "../img/green_survey.png" : item.category === "Business Operations" ? "../img/dark_green_survey.png" : item.category === "Customer Service" ? "../img/gray_survey.png" : item.category === "Governance & Compliance" ? "../img/pink_survey.png" : item.category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png" } /> */}
                                              </div>
                                              <div className="content-title-section" onClick={() => { navigate(`/form/dynamic/${inner_item.form_name}`); }}>
                                                <h6
                                                // onClick={() => {
                                                //   navigate(
                                                //     `/form/dynamic/${inner_item.form_name}`
                                                //     // `/form/response/${inner_item.id}`
                                                //   );
                                                // }}
                                                >
                                                  {inner_item.form_name}
                                                </h6>
                                                <h4> Created on:{" "} {moment(inner_item.createdAt).utcOffset("+11:00").format("DD/MM/YYYY")} </h4>
                                              </div>
                                              <div className="content-toogle">
                                                <Dropdown>
                                                  <Dropdown.Toggle id="dropdown-basic1"> <FontAwesomeIcon icon={faEllipsisVertical} /> </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        OpenViewResponse(`/form/response/${inner_item.id}`, inner_item)
                                                        // if (inner_item.end_date) {
                                                        //   let todayDate = new Date();
                                                        //   todayDate = new Date(todayDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
                                                        //   todayDate = new Date(todayDate);
                                                        //   let dataAndTime = inner_item.end_date + " " + inner_item.end_time;
                                                        //   let endDate = new Date(dataAndTime);
                                                        //   endDate = new Date(endDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
                                                        //   endDate = new Date(endDate);
                                                        //   if (todayDate.getTime() > endDate.getTime())
                                                        //     toast.error("Your form was expired on " + moment(inner_item.end_date).format("DD/MM/YYYY") + ".");
                                                        //   else
                                                        //     navigate(`/form/response/${inner_item.id}`
                                                        //       // `/form/dynamic/${inner_item.form_name}`
                                                        //     );
                                                        // } else
                                                        //   navigate(`/form/response/${inner_item.id}`
                                                        //     // `/form/dynamic/${inner_item.form_name}`
                                                        //   );
                                                      }}
                                                    > <FontAwesomeIcon icon={faEye} /> View Response </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      ) : null;
                                    })}
                                  </Row>
                                </>
                              )
                            );
                          })}
                        </div>
                        {no_record === false && (<Row> <p>No Record founds</p> </Row>)}
                      </Tab>

                      <Tab eventKey="forms-history" title="Forms History">
                        <div className="forms-content-section">
                          {formData?.map((item, index) => {
                            return (
                              ((item.category === "Talent Management" && LSUserRole !== "guardian") || LSUserRole !== "guardian" || (item.category !== "Talent Management" && LSUserRole === RoleGuardian)) && (
                                <>
                                  {(item["title_flag"] = false)}
                                  <Row>
                                    {item?.forms?.map((inner_item, inner_index) => {
                                      let fieldPermission = [];
                                      inner_item?.form_fields?.map((fields) => {
                                        fields?.form_field_permissions[0] !== undefined && fieldPermission.push(fields?.form_field_permissions[0].fill_access_users);
                                      });
                                      let array1 = [];
                                      if (fieldPermission.length > 0) {
                                        fieldPermission.map((permission) => {
                                          if (permission !== undefined && permission !== "" && permission !== null) {
                                            for (let name of permission) {
                                              array1.push(name);
                                            }
                                          }
                                        });

                                        fieldPermission = [];
                                        fieldPermission = array1;
                                      }
                                      return ((
                                        (typeof inner_item?.form_permissions !== "undefined" &&
                                          inner_item?.form_permissions[0]?.fill_access_users) ||
                                        []
                                      ).includes("parent") &&
                                        ((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.target_user) || []).includes("parent")
                                        ? (inner_item?.form_filled_user || []).includes(LSUserId)
                                        : (inner_item?.form_filled_user || []).includes(LSUserRole === RoleGuardian ? LSChildId : LSUserId)) &&
                                        (((typeof fieldPermission !== "undefined" && fieldPermission) || []
                                        )?.includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole) ||
                                          ((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.fill_access_users) || []
                                          ).includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole
                                          ) || ((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.fill_access_users) || []).includes(LSUserRole === RoleGuardian ? LSChildId || LSUserId : LSUserId)) ? (
                                        <>
                                          {item.title_flag === false && (
                                            <>
                                              {(item["title_flag"] = true)}
                                              {(form_history_no_record = true)}
                                              <div className="col-lg-12" key={inner_index + "5"}>
                                                <h2 className="page_title">{item.category}</h2>
                                              </div>
                                            </>
                                          )}
                                          <Col lg={4} key={inner_index + "6"}>
                                            <div className="forms-content create-other">
                                              <div className="content-icon-section" onClick={() => method1(formData, index, inner_index, inner_item)} >
                                                <img src={getCategoryImage(item.category)} />
                                                {/* <img src={ item.category === "Parent Forms" ? "../img/survey_icon.png" : item.category === "Talent Management" ? "../img/blue_survey.png" : item.category === "Child Care Forms" ? "../img/green_survey.png" : item.category === "Business Operations" ? "../img/dark_green_survey.png" : item.category === "Customer Service" ? "../img/gray_survey.png" : item.category === "Governance & Compliance" ? "../img/pink_survey.png" : item.category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png" } /> */}
                                              </div>
                                              <div className="content-title-section" onClick={() => method1(formData, index, inner_index, inner_item)} >
                                                <h6>{inner_item.form_name}</h6>
                                                <h4> Created on:{" "} {moment(inner_item.createdAt).utcOffset("+11:00").format("DD/MM/YYYY")} </h4>
                                              </div>
                                              <div className="content-toogle">
                                                <Dropdown>
                                                  <Dropdown.Toggle id="dropdown-basic1"> <FontAwesomeIcon icon={faEllipsisVertical} /> </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        OpenViewResponse(`/form/dynamic/${inner_item.form_name}`, inner_item)
                                                        // if (inner_item.end_date) {
                                                        //   let todayDate = new Date();
                                                        //   todayDate = new Date(todayDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
                                                        //   todayDate = new Date(todayDate);
                                                        //   let dataAndTime = inner_item.end_date + " " + inner_item.end_time;
                                                        //   let endDate = new Date(dataAndTime);
                                                        //   endDate = new Date(endDate).toLocaleString("en-ZA", { timeZone: "Australia/Perth", });
                                                        //   endDate = new Date(endDate);
                                                        //   if (todayDate.getTime() > endDate.getTime())
                                                        //     toast.error("Your form was expired on " + moment(inner_item.end_date).format("DD/MM/YYYY") + ".");
                                                        //   else
                                                        //     navigate(`/form/dynamic/${inner_item.form_name}`);
                                                        // } else
                                                        //   navigate(`/form/dynamic/${inner_item.form_name}`);
                                                      }}
                                                    >
                                                      <FontAwesomeIcon icon={faPen} /> Add Response
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                              {/* )} */}
                                            </div>
                                          </Col>
                                        </>
                                      ) : null;
                                    })}
                                  </Row>
                                </>
                              )
                            );
                          })}
                        </div>
                        {form_history_no_record === false && (<Row> <p>No Record founds</p> </Row>)}
                      </Tab>

                      <Tab eventKey="form-templates" title="Form Templates and Responses">
                        <div className="tab-created">
                          <Tabs
                            defaultActiveKey={LSUserRole === monaRoles.coordinator || LSUserRole === monaRoles.educator || LSUserRole === RoleGuardian ? "created-by-others" : key}
                            id="uncontrolled-tab-example" className="mb-3" onSelect={(k) => { setKey(k); }}
                          >
                            {!(LSUserRole === monaRoles.coordinator || LSUserRole === monaRoles.educator || LSUserRole === RoleGuardian) && (
                              <Tab className="create-me create_by_me_list" eventKey="created-by-me" title="Created by me" >
                                <div className="forms-content-section">
                                  {MeFormData?.length > 0 && MeFormData?.map((item, index) => {
                                    return (
                                      <>
                                        <Row key={index + "7"}> <div className="col-lg-12"> <h2 className="page_title">{item.category}</h2> </div> </Row>
                                        <Row key={index + "8"}>
                                          {item?.forms?.map((inner_item, inner_index) => {
                                            if (inner_item?.created_by == ParsedLSUserId && meformDataStatus == false) { setMeformDataStatus(true); }

                                            return (inner_item.created_by === ParsedLSUserId && (
                                              <Col lg={4} key={inner_index + "9"}>
                                                <div className="forms-content create-other">
                                                  <div className="content-icon-section">
                                                    <img src={getCategoryImage(item.category)} />
                                                    {/* <img src={ item.category === "Parent Forms" ? "../img/survey_icon.png" : item.category === "Talent Management" ? "../img/blue_survey.png" : item.category === "Child Care Forms" ? "../img/green_survey.png" : item.category === "Business Operations" ? "../img/dark_green_survey.png" : item.category === "Customer Service" ? "../img/gray_survey.png" : item.category === "Governance & Compliance" ? "../img/pink_survey.png" : item.category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png" } /> */}
                                                  </div>
                                                  {getResponseCount(inner_item, inner_index, index)}
                                                  <div className="content-toogle">
                                                    {getUserImgCount(inner_item, inner_index, index)}
                                                    {bindDropDown(inner_item, item, true)}
                                                  </div>
                                                </div>
                                              </Col>
                                            ));
                                          })}
                                        </Row>
                                      </>
                                    );
                                  })}

                                  {meformDataStatus == true ? "" : "No Form Created by You "}
                                </div>
                              </Tab>
                            )}
                            <Tab className="create-me" eventKey="created-by-others" title="Created by others" >
                              <div className="forms-content-section">
                                {OthersFormData?.length > 0 && OthersFormData?.map((item, index) => {
                                  { item["title_flag"] = false; }
                                  return (
                                    <>
                                      <Row key={index + "10"}>
                                        {item?.forms?.map((inner_item, inner_index) => {
                                          if (inner_item?.created_by !== ParsedLSUserId && otherformDataStatus == false) { setOtherformDataStatus(true); }

                                          return (
                                            inner_item.created_by !== ParsedLSUserId &&
                                            (((typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.response_visibility) || []).includes(LSUserId) ||
                                              (
                                                (typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.response_visibility) || []
                                              ).includes(LSUserRole) ||
                                              (
                                                (typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.signatories_role) || []
                                              ).includes(LSUserId) ||
                                              (
                                                (typeof inner_item?.form_permissions !== "undefined" && inner_item?.form_permissions[0]?.signatories_role) || []
                                              ).includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole)) && (
                                              <>
                                                {item.title_flag === false && (
                                                  <>
                                                    {(item["title_flag"] = true)}
                                                    <Col lg={12} key={inner_index + "11"}> <h2 className="page_title"> {item.category} </h2> </Col>
                                                  </>
                                                )}
                                                <Col lg={4} key={inner_index + "12"}>
                                                  <div className="forms-content">
                                                    <div className="create-other">
                                                      <div className="content-icon-section">
                                                        <img src={getCategoryImage(item.category)} />
                                                        {/* <img src={item.category === "Parent Forms" ? "../img/survey_icon.png" : item.category === "Talent Management" ? "../img/blue_survey.png" : item.category === "Child Care Forms" ? "../img/green_survey.png" : item.category === "Business Operations" ? "../img/dark_green_survey.png" : item.category === "Customer Service" ? "../img/gray_survey.png" : item.category === "Governance & Compliance" ? "../img/pink_survey.png" : item.category === "General" ? "../img/orange_survey.png" : "../img/survey_icon.png"} /> */}
                                                      </div>
                                                      {getResponseCount(inner_item, inner_index, index)}
                                                      <div className="content-toogle">
                                                        {getUserImgCount(inner_item, inner_index, index)}
                                                        {LSUserRole === MonaRoles.franchisorAdmin && bindDropDown(inner_item, item, true)}
                                                      </div>
                                                    </div>
                                                    <div className="create-by">
                                                      <div className="create-by-heading"> {" "} <h5>Created by:</h5>{" "} </div>
                                                      <div className="user-img-other">
                                                        <img src={inner_item?.user?.profile_photo ? inner_item?.user?.profile_photo : "../../img/user-other.svg"} ></img>
                                                      </div>
                                                      <div className="user-name-other">
                                                        <p>{inner_item?.user?.fullname},</p>
                                                        <span className="text-capitalize"> {" " + inner_item?.user?.role.split("_").join(" ")} </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </>
                                            )
                                          );
                                        })}
                                      </Row>
                                    </>
                                  );
                                })}

                                {otherformDataStatus == true ? "" : "No Form Created by Other "}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </Tab>

                    </Tabs>
                  </div>
                </div>
                <ToastContainer />
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal className="responses_model" show={viewResponseFlag} onHide={() => { setViewResponseFlag(false); getFormData("", localStorage.getItem("franchisee_id"), "Response_modal"); }} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="../img/survey.png" />
            <h1>Form Responses</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user_list_wrp">
            <div className="table_head">
              <div className="user_box">
                <div className="user_name">Name</div>
                <div className="user_role">User Role</div>
                <div className="date">Date</div>
              </div>
            </div>
            <div className="table_body">
              {key === "created-by-me"
                ? MeFormData[Index]?.forms && (
                  // MeFormData[Index]?.forms[innerIndex]
                  <GenerateMeFromUsers educatorIDs={educatorIDs} MeFormData={SelectedFormData} LSUserRole={LSUserRole} LSUserId={LSUserId} />
                )
                : OthersFormData[Index]?.forms && (
                  // OthersFormData[Index]?.forms[innerIndex]
                  <GenerateOthersFromUsers educatorIDs={educatorIDs} MeFormData={SelectedFormData} LSUserRole={LSUserRole} ParsedLSUserId={ParsedLSUserId} LSUserId={LSUserId} />
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewFormBuilder;

function GenerateMeFromUsers(props) {
  const { MeFormData, educatorIDs, LSUserRole, LSUserId } = props;
  const userListed = [];
  return MeFormData.form_data.map((item) => {
    const item0 = item; //item[0];
    if (!userListed.includes(item0?.user?.id)) {
      userListed.push(item0?.user?.id);
      return LSUserRole === MonaRoles.educator ? (educatorIDs.includes(parseInt(item0?.behalf_of)) && (
        <GetUserRow item={item} MeFormData={MeFormData} types={"me"} LSUserRole={LSUserRole} LSUserId={LSUserId} />
      )
      ) : (<GetUserRow item={item} MeFormData={MeFormData} types={"me"} LSUserRole={LSUserRole} LSUserId={LSUserId} />);
    }
  });
}

function GenerateOthersFromUsers(props) {
  const { MeFormData, educatorIDs, LSUserRole, LSUserId, ParsedLSUserId } = props;
  const userListed = [];
  return MeFormData.form_data.map((item) => {
    if (!userListed.includes(item[0]?.user?.id)) {
      userListed.push(item[0]?.user?.id);
      return LSUserRole === monaRoles.educator || LSUserRole === monaRoles.guardian || LSUserRole === monaRoles.parent ? (   //changes Bug 35 [17-Oct-2024]
        (educatorIDs?.includes(parseInt(item[0]?.behalf_of)) ||
          parseInt(item[0]?.behalf_of) === ParsedLSUserId) && (
          <GetUserRow item={item} MeFormData={MeFormData} types={"others"} LSUserRole={LSUserRole} LSUserId={LSUserId} />
        )
      ) : (
        <GetUserRow item={item} MeFormData={MeFormData} types={"others"} LSUserRole={LSUserRole} LSUserId={LSUserId} />
      );
    }
  });
}

function GetUserRow(props) {
  const { item, MeFormData, types, LSUserRole, LSUserId } = props;
  const navigate = useNavigate();
  const item0 = item;////item[0];
  item0.seen_flag = item0?.seen_by?.includes(LSUserId);
  //item0.seen_flag = item.
  const role = item0?.user?.role.split("_").join(" ");
  return (
    <div className="user_box">
      <div className="user_name">
        <div className="user_profile">
          <img src={item0?.user?.profile_photo ? item0?.user?.profile_photo : "../img/user_img.png"} alt="" />
          <h4 className={item0?.seen_flag === false && "bold-user-info"}> {item0?.user?.fullname} </h4>
        </div>
      </div>
      <div className="user_role">
        <div className="user_detail">
          <h4 className={item0?.seen_flag === false ? "bold-user-info text-capitalize" : "text-capitalize"} > {role} </h4>
        </div>
      </div>
      <div className="date">
        <div className="user_detail">
          <h4 className={item0?.seen_flag === false && "bold-user-info"}>
            {moment(item0?.createdAt).format("DD/MM/YYYY")} -{" "}
            {item0?.createdAt.split("T")[1].split(".")[0].split(":", 2).join(":")} Hrs
          </h4>
          <button
            onClick={() => {
              if (types == "others") {
                if (
                  MeFormData?.form_permissions[0]?.signatories_role ||
                  [].includes(LSUserId) ||
                  MeFormData?.form_permissions[0]?.signatories_role ||
                  [].includes(LSUserRole === RoleGuardian ? "parent" : LSUserRole)
                ) {
                  navigate(`/form/response/${item0?.form_id}/${item0?.user?.id}`, {
                    state: { id: MeFormData?.id, form_name: MeFormData?.form_name, signature_access: true, },
                  });
                } else {
                  navigate(`/form/response/${item0?.form_id}/${item0?.user?.id}`, {
                    state: { id: MeFormData?.id, form_name: MeFormData?.form_name, signature_access: false, },
                  });
                }
              }
              else {
                navigate(`/form/response/${item0?.form_id}/${item0?.user?.id}`, {
                  state: { id: MeFormData.id, form_name: MeFormData.form_name, },
                });
                // navigate(`/user/form/response/${MeFormData.id}/${item[0]?.user?.id}/${role}`, {
                //   state: {
                //     backToForm: "true",
                //   },
                // });
              }
              //user/form/response/446/1274/educator
              // navigate(`/form/response/${item[0]?.form_id}`, {
              //   state: {
              //     id: MeFormData.id,
              //     form_name: MeFormData.form_name,
              //   },
              // });
            }}
          >
            View Response
          </button>
        </div>
      </div>
    </div>
  );
}
